import { AppConfig } from "../config/app.config";
import { IIframe } from "../interfaces/iframe.interface";
import { IPostMessage } from "../interfaces/post-message.interface";
import { EventEmitter } from "../components/event-emitter";
import { IStoreProvider } from "../interfaces/store-provider.interface";
import { QuizState } from "../components/quiz-state";
import { PostMessageEvent } from "../enums/post-message-event.enum";
import { postMessageSchema } from '../dto/post-message.schema';

export class PostMessageProvider {
  constructor(
    private readonly iframe: IIframe,
    private readonly config: AppConfig,
    private readonly storeProvider: IStoreProvider<QuizState>
  ) { 
    window.addEventListener('message', this.handlePostMessage);
  }
  
  private eventEmitter = new EventEmitter<PostMessageEvent>();

  public async send<T extends {}>(event: PostMessageEvent, data: Record<string, any> = {}, post_message_id?: number): Promise<IPostMessage<T> | undefined> {
    const postMessage: IPostMessage = { 
      event, data, 
      access_key: this.config.post_message_access_key, 
      post_message_id: post_message_id || new Date().getTime() 
    };

    try {
      postMessageSchema.parse(postMessage);

      await new Promise<void>(resolve => {
        const sendPostMessage = () => {
          this.iframe.window?.postMessage(JSON.stringify(postMessage), this.config.quiz_host);
          resolve();
        }
        
        if (this.storeProvider.getState().wasLoaded) {
          sendPostMessage();
        } else {
          this.eventEmitter.subscribe(PostMessageEvent.LOAD, () => sendPostMessage());
        }
      });

      return new Promise<IPostMessage<T>>(resolve => {
        const callback = (response: IPostMessage<T>) => {
          if (response.post_message_id == postMessage.post_message_id) {
            resolve(response);
            this.eventEmitter.unsubscribe(event, callback);
          }
        }

        this.subscribe(event, callback);
      });

    } catch (e) {
      console.error(e);
    }
  }

  public subscribe<T extends {}>(event: PostMessageEvent, callback: (data: IPostMessage<T>) => void) {
    return this.eventEmitter.subscribe<IPostMessage<T>>(event, callback);
  }

  public unsubscribe(event: PostMessageEvent, callback: (data: any) => void) {
    this.eventEmitter.unsubscribe(event, callback);
  }

  private handlePostMessage = ({ data }: any) => {
    let message: IPostMessage;
    if (typeof data == "string") {
      try {
        message = JSON.parse(data);
        postMessageSchema.parse(message);

        this.eventEmitter.emit(message.event, message);
      } catch(error) { 
        if (this.config.env == "development") console.warn(error);
      }
    }
  }
}