import { z } from 'zod';
import { FormType } from '../../../enums/form-type.enum';
import { OpenTrigger } from '../../../enums/open-trigger.enum';
export const visitSchema = z.object({
  quizId: z.string(),
  leadId: z.optional(z.string()),
  event_open: z.optional(z.boolean()),
  event_close: z.optional(z.boolean()),
  event_click_cross: z.optional(z.boolean()),
  event_start: z.optional(z.boolean()),
  event_start_by_scroll: z.optional(z.boolean()),
  event_start_by_flying_btn: z.optional(z.boolean()),
  event_choose_distributor_answer: z.optional(z.boolean()),
  event_reach_question_1: z.optional(z.boolean()),
  event_reach_question_2: z.optional(z.boolean()),
  event_reach_question_3: z.optional(z.boolean()),
  event_reach_question_4: z.optional(z.boolean()),
  event_reach_question_5: z.optional(z.boolean()),
  event_reach_question_6: z.optional(z.boolean()),
  event_reach_question_7: z.optional(z.boolean()),
  event_reach_question_8: z.optional(z.boolean()),
  event_reach_question_9: z.optional(z.boolean()),
  event_reach_question_10: z.optional(z.boolean()),
  event_reach_phone_form: z.optional(z.boolean()),
  event_get_lead: z.optional(z.boolean()),
  event_get_appointment: z.optional(z.boolean()),
  event_click_final_page_button: z.optional(z.boolean()),
  event_click_trademark: z.optional(z.boolean()),
  event_click_header_phone: z.optional(z.boolean()),
  event_click_whatsapp_button: z.optional(z.boolean()),
  event_click_minor_button: z.optional(z.boolean()),
  event_show_flying_btn: z.optional(z.boolean()),
  form_type: z.optional(z.nativeEnum(FormType)),
  open_trigger: z.optional(z.nativeEnum(OpenTrigger)),
  domain: z.string(),
  path: z.optional(z.string()),
  queries: z.optional(z.record(z.string(), z.string())),
  href: z.string()
});

export const patrialVisitSchema = visitSchema.partial();

export type IVisitSchema = z.infer<typeof visitSchema>;