import { IAnalyticsPlugin } from "./interfaces/analytics-plugin.interface";
import { AppConfig } from "../../config/app.config";
import { IABTest } from "./interfaces/abtest.interface";
import { IAnalyticsBindEventData } from "./interfaces/analytics-data.interface";

declare global {
  interface Window {
    dataLayer: any
  }
}

export class GTag implements IAnalyticsPlugin {
  constructor(
    private readonly track_number: string,
    private readonly config: AppConfig,
    private readonly bindEventsList?: IAnalyticsBindEventData[]
  ) {
  }
  
  private gtag: (...args: any) => void;
  
  public init() {
    const scriptId = 'ga-gtag';
    
    if (document.getElementById(scriptId)) return;
    
    let script: HTMLScriptElement | null = document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${this.track_number}"]`);
    
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.track_number}`;
      document.head.insertBefore(script, document.head.firstChild);

      window.dataLayer = window.dataLayer || [];
  
      this.gtag = function() {
        window.dataLayer.push(arguments);
      }

      this.gtag('js', new Date());
      this.gtag('config', this.track_number, { debug: this.config.env == "development" });
    }
  }

  public track(event: string, params) {
    this.gtag("event", event, params);
  }

  public setParams(params: Record<string, any>) {
    this.gtag("event", "params", params);
  }

  public setABTest(abTest: IABTest) {
    this.gtag("event", "ABTest", {
      event_category: abTest.test_name,
      event_action: abTest.test_variant
    });
  }
}