import { QuizEvent } from "../enums/quiz-event.enum";
import { QuizStatus } from "../enums/quiz-status.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { IQuiz } from "../interfaces/quiz.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { getQueryParams } from "../utils/get-query-params";
import { EventEmitter } from "./event-emitter";
import { PostMessageProvider } from "../providers/post-message.provider";
import { QuizState } from "./quiz-state";
import { IStoreProvider } from '../interfaces/store-provider.interface';
import { QuizMode } from '../enums/quiz-mode.enum';
import { ReachGoalData } from '../plugins/analytics/interfaces/reach-goal-data.interface';
import { OpenTrigger } from '../enums/open-trigger.enum';
import { IHttpProvider } from '../interfaces/http-provider.interface';
import { getCookie } from "../utils/get-cookie";
import { IGeolocationProvider } from "../interfaces/geolocation-provider.interface";
import { IABTest } from "../plugins/analytics/interfaces/abtest.interface";
import { IFixBlockOptions } from "../plugins/fix-block/interfaces/fix-block-options.interface";
import { IQuizData } from "../interfaces/quiz-data.interface";
import { IPostMessage } from '../interfaces/post-message.interface';
import { PostMessageEvent } from "../enums/post-message-event.enum";
import { ILead } from "../interfaces/lead.interface";
import { IQuizFormRequestBody } from '../interfaces/quiz-form-request-body.interface';
import { QuestionType } from "../enums/question-type.enum";
import { supplementObject } from '../utils/supplement-object';
import { IUpdateLeadData } from "../interfaces/update-lead-data.interface";
import { IOptionsSchema } from '../dto/options.schema';
import { IPlugin } from "../interfaces/plugin.interface";

export class Quiz implements IQuiz {
  constructor(
    private readonly options: IOptionsSchema,
    private readonly storeProvider: IStoreProvider<QuizState>,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>,
    public readonly postMessageProvider: PostMessageProvider,
    private readonly storageProvider: IStorageProvider,
    private readonly iframe: IIframe,
    private readonly httpProvider: IHttpProvider,
    private readonly geoLocationProvider?: IGeolocationProvider
  ) {}

  private quizData: IQuizData;

  public async init(): Promise<void> {
    this.storeProvider.updateState({ status: QuizStatus.INITIALIZING });
    this.quizData = await this.httpProvider.get<IQuizData>(`/quizzes/${this.options.id}/?imageFormat=object`);

    if (!this.quizData) {
      this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
      throw new Error("Quiz is not available");
    }
    
    if (this.quizData.content.questions[0].type == QuestionType.DISTRIBUTOR && this.options.answerFilter?.length) {
      if (this.options.answerFilter?.length == 1) {
        const selectedAnswer = this.quizData.content.questions[0].answers?.find(answer => {
          if (this.options.answerFilter) {
            return answer.id == this.options.answerFilter[0];
          } else return false;
        });

        const quizId = selectedAnswer?.quizContent;

        if (!quizId) {
          this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
          throw new Error("Correct answer id is not found");
        }

        const quizContent = await this.httpProvider.get<IQuizData>(`/quiz-contents/${quizId}/?imageFormat=object`);

        this.quizData.content = supplementObject(quizContent.content, this.quizData.content);
        this.quizData.settings = { ...this.quizData.settings, ...quizContent.settings };
        this.storeProvider.updateState({ distributorSelectedAnswer: selectedAnswer });

        if (!this.quizData) {
          this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
          throw new Error("Quiz is not available");
        }
      } else {
        
        this.quizData.content.questions[0].answers = this.quizData.content.questions[0].answers?.filter(answer => {
          return this.options.answerFilter?.includes(String(answer.id))
        }).sort((itemA, itemB) => {
          const itemAIndex = this.options.answerFilter?.findIndex(item => item == String(itemA.id)) || -1;
          const itemBIndex = this.options.answerFilter?.findIndex(item => item == String(itemB.id)) || -1;
          return itemAIndex - itemBIndex;
        });
      }
    }

    if (this.options.customContent) {
      this.quizData.content = supplementObject<any>(this.options.customContent, this.quizData.content);
    }

    this.postMessageProvider.subscribe<Record<string, any>>(PostMessageEvent.GET_QUIZ, async ({ data, post_message_id }) => {
      const response = await this.httpProvider.get<any>(`/quizzes/${data.quizId}?imageFormat=object`);
      this.postMessageProvider.send(PostMessageEvent.GET_QUIZ, response, post_message_id);
    });

    this.postMessageProvider.subscribe(PostMessageEvent.GET_INITIAL_DATA, ({ post_message_id }) => {
      this.storeProvider.updateState({ wasLoaded: true });
      this.sendInitialData(post_message_id);
    });

    this.postMessageProvider.subscribe<IQuizFormRequestBody>(PostMessageEvent.GET_LEAD, async ({ data, post_message_id }) => {
      const lead = await this.createLead(data);
      this.postMessageProvider.send(PostMessageEvent.GET_LEAD, lead, post_message_id)
      this.quizEventEmitter.emit(QuizEvent.GET_LEAD, { lead });
    });

    this.postMessageProvider.subscribe<IUpdateLeadData>(PostMessageEvent.UPDATE_LEAD, async ({ data, post_message_id }) => {
      const lead = await this.updateLead(data.id, data.leadFields);
      this.postMessageProvider.send(PostMessageEvent.UPDATE_LEAD, lead, post_message_id);
    })

    this.iframe.init();
    this.iframe.loadContent();

    if (this.options.mode == QuizMode.CUSTOM) {
      this.open(OpenTrigger.QUIZ_MODE_CUSTOM);
    }

    if (this.options.mode == QuizMode.FULL) {
      this.open(OpenTrigger.QUIZ_MODE_FULL);
      this.addMeta();
    }
  
    const popupTimeout = this.storageProvider.getItem("popup");

    if (this.options.mode == QuizMode.POPUP) {
      if (this.options.autoOpen && !popupTimeout) this.autoOpen(this.options.autoOpen);
      this.postMessageProvider.subscribe<IPostMessage>(PostMessageEvent.CLICK_CROSS, () => this.close());
    }

    if (this.options.openOnScroll && !popupTimeout) {
      window.addEventListener("scroll", () => this.onScroll());
    }

    if (this.options.openOnLeave && !popupTimeout) {
      document.addEventListener("mouseleave", (e) => this.onLeave(e));
    }

    this.quizEventEmitter.emit(QuizEvent.INIT);
    this.storeProvider.updateState({ initialized: true, status: QuizStatus.CLOSED });
  }

  public initialized() {
    return this.storeProvider.getState().initialized;
  }

  public async open(trigger: OpenTrigger = OpenTrigger.OTHER, pageIndex = 0) {
    const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60 * this.options.autoOpenLimit);

    // Если плагин в процессе инициализации, то ожидаем
    if (this.storeProvider.getState().status == QuizStatus.INITIALIZING) {
      await new Promise<void>(resolve => this.quizEventEmitter.subscribe(QuizEvent.INIT, () => resolve()));
    }

    if (!this.storeProvider.getState().initialized) throw new Error("Необходима инициализация");

    if (this.storeProvider.getState().status == QuizStatus.OPENED) return;
    this.iframe.show();
    this.storageProvider.addItem("popup", true, expiryDate);

    const openAction = (resolve: () => void) => {
      this.postMessageProvider.send(PostMessageEvent.OPEN, { trigger, pageIndex });
      this.storeProvider.updateState({
        status: QuizStatus.OPENED,
        wasOpened: true,
      });
      this.quizEventEmitter.emit(QuizEvent.OPEN);
      resolve();
    };
    
    return new Promise<void>(resolve => {
      if (this.storeProvider.getState().wasLoaded) return openAction(resolve);
        else this.postMessageProvider.subscribe(PostMessageEvent.LOAD, () => openAction(resolve)).unsubscribeOnEmit();
    });
  }

  public close() {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) {
      this.storeProvider.updateState({
        status: QuizStatus.CLOSED,
        wasClosed: true,
      });
      this.iframe.hide();
      this.quizEventEmitter.emit(QuizEvent.CLOSE);
    }
  }

  public bind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.subscribe(event, callback);
  }

  public unbind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.unsubscribe(event, callback);
  }

  public bindPostMessageEvent<T extends {}>(event: PostMessageEvent, callback: (params: IPostMessage<T>) => void):void {
    this.postMessageProvider.subscribe<T>(event, callback);
  }

  public emitEvent<T>(event: QuizEvent, params: T) {
    this.quizEventEmitter.emit(event, params);
  }

  public setAutoOpen(seconds?: number) {
    const timer = seconds || this.options.autoOpen;
    if (timer) this.autoOpen(timer);
  }

  public sendLeadExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send<{}>(PostMessageEvent.SEND_EXTRA_PARAMS, params);
  }

  public setHeaderPhone(phone: string) {
    return this.postMessageProvider.send<{}>(PostMessageEvent.SET_HEADER_PHONE, { phone });
  }

  public enableWebvisor(counter: number) {
    return this.postMessageProvider.send(PostMessageEvent.ENABLE_WEBVISOR, { 
      counter, 
      domain: window.location.hostname 
    });
  }

  private async createLead(quizFormRequestBody: IQuizFormRequestBody) {
    const response = await this.httpProvider.post<ILead>(`/leads/`, {
      ...quizFormRequestBody,
      quiz: this.options.id,
      extra: {
        ...quizFormRequestBody.extra,
        ...getQueryParams(),
        ...this.options.leadExtraParams,
        href: window.location.href,
        domain: document.domain,
        cookies: {
          _ga: getCookie("_ga"),
          _fbp: getCookie("_fbp"),
          _ym_uid: getCookie("_ym_uid"),
          roistat_visit: getCookie("roistat_visit"),
          roistat_first_visit: getCookie("roistat_first_visit"),
          roistat_visit_cookie_expire: getCookie("roistat_visit_cookie_expire"),
        },
      }
    }, 10);

    return response;
  }

  private async updateLead(id: string, body: Record<string, any>) {
    const response = await this.httpProvider.patch(`/leads/${id}`, body);
    return response;
  }

  private async sendInitialData(post_message_id: number) {
    const geolocation = await this.geoLocationProvider?.getGeoData();

    this.postMessageProvider.send(PostMessageEvent.GET_INITIAL_DATA, {
      quizData: this.quizData,
      mode: this.options.mode,
      domain: document.domain,
      geolocation: geolocation,
      distributorSelectedAnswer: this.storeProvider.getState().distributorSelectedAnswer
    },
    post_message_id
    );
  }

  private autoOpen(seconds: number) {
    const { autoOpenTimeoutId } = this.storeProvider.getState();
    if (autoOpenTimeoutId) clearTimeout(autoOpenTimeoutId);

    const timeoutId = setTimeout(() => {
      const { wasOpened, status } = this.storeProvider.getState();
      if (!wasOpened && status == QuizStatus.CLOSED) {
        this.open(OpenTrigger.AUTO_OPEN);
      }
    }, seconds * 1000);

    this.storeProvider.updateState({
      autoOpenTimeoutId: timeoutId
    });
  }

  private onScroll(): void {
    const { pageYOffset, innerHeight } = window;

    if (this.storeProvider.getState().wasOpened) {
      return window.removeEventListener("scroll", this.onScroll);
    }

    if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) {
      this.open(OpenTrigger.SCROLL);
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  private onLeave(event: MouseEvent): void {
    if (this.storeProvider.getState().wasOpened) {
      return document.removeEventListener("mouseleave", this.onLeave);
    }

    if (event.clientY < 0) {
      this.open(OpenTrigger.LEAVE);
      document.removeEventListener("mouseleave", this.onLeave);
    }
  }

  private addMeta() {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }

  public async addFixBlock(options: IFixBlockOptions = {}) {
    const FixBlock = (await import('../plugins/fix-block')).default;
    const fixblock = new FixBlock(options);
    return this.use(fixblock);
  }

  public use(plugin: IPlugin) {
    plugin.init(this);
    return plugin;
  }
}