import { IABTest } from "./interfaces/abtest.interface";
import { IAnalyticsPlugin } from "./interfaces/analytics-plugin.interface";
import { IPlugin } from "../../interfaces/plugin.interface";
import { IQuiz } from '../../interfaces/quiz.interface';
import { IAnalyticsData } from './interfaces/analytics-data.interface';
import { AnalyticsType } from "./enums/analytics-type.enum";
import { YaMetrikaCounter } from "./ya-metrika";
import { GTag } from "./gtag";
import { appConfig } from "../../config/app.config";
import { ReachGoalData } from "./interfaces/reach-goal-data.interface";
import { PostMessageEvent } from "../../enums/post-message-event.enum";
import { SendAnalyticsData } from "./interfaces/send-analytics-data.interface";
import { QuizEvent } from "../../enums/quiz-event.enum";
import { OwnAnalytics } from './own-analytics';

export default class Analytics implements IPlugin {
  constructor(private readonly datas: IAnalyticsData[]) {}

  private plugins: IAnalyticsPlugin[] = [];
  private quiz: IQuiz;

  public init(quiz: IQuiz) {
    this.quiz = quiz;

    this.datas.forEach(data => {
      if (data.type == AnalyticsType.YA_METRIKA) this.plugins.push(new YaMetrikaCounter(Number(data.id), data.bindEventsList));
      if (data.type == AnalyticsType.GA_3) this.plugins.push(new GTag(String(data.id), appConfig(), data.bindEventsList));
      if (data.type == AnalyticsType.OWN_ANALYTICS) this.plugins.push(new OwnAnalytics(String(data.id), appConfig()));
    });

    this.plugins.forEach(plugin => plugin.init());

    this.quiz.bindPostMessageEvent<ReachGoalData>(PostMessageEvent.REACH_GOAL, message => {
      this.quiz.emitEvent(QuizEvent.REACH_GOAL, message.data);
    });

    this.quiz.bindPostMessageEvent<SendAnalyticsData>(PostMessageEvent.SEND_ANALYTICS, message => {
      this.track(message.data.event, message.data.params);
    });
    
    this.quiz.bindPostMessageEvent<IABTest>(PostMessageEvent.SET_AB_TEST, message => {
      this.setABTest(message.data);
    });
  }

  private track(event: string, params?: Record<string, any>) {
    this.plugins.forEach(plugin => plugin.track(event, params));
  }

  private setABTest(abTest: IABTest) {
    this.plugins.forEach(plugin => plugin.setABTest(abTest));
  }
}