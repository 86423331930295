import { z } from "zod";
import { QuizMode } from "../enums/quiz-mode.enum";
import { analyticsDataSchema } from "./analytics-data.schema";
import { quizContentSchema } from './quiz-content.schema';

export const optionsSchema = z.object({
  id: z.string(),
  mode: z.nativeEnum(QuizMode),
  autoOpen: z.optional(z.number().min(0).max(600)),
  autoOpenLimit: z.number().optional().default(1),
  openOnScroll: z.boolean().optional().default(false),
  openOnLeave: z.boolean().default(true),
  host: z.optional(z.string().url("Параметр host должен быть url адресом")),
  customParams: z.optional(z.record(z.any())),
  enableGeolocation: z.boolean().optional().default(false),
  iframe: z.optional(z.string()),
  answerFilter: z.optional(z.union([z.string(), z.number()]).array()),
  leadExtraParams: z.optional(z.record(z.any())),
  customContent: z.optional(quizContentSchema),
  analytics: z.optional(analyticsDataSchema.array())
});

export type IOptionsSchema = z.infer<typeof optionsSchema>;