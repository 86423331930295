import { clearObject } from "../utils/clear-object";

export const appConfig = <T extends {} = any>(args?: Partial<T>) => ({
    env: process.env.NODE_ENV || "production",
    quiz_host: process.env.QUIZ_HOST || "https://cdn.reserve-donkin-leads.ru/universal/client/v5/index.html",
    api_host: process.env.API_HOST || "https://un.reserve-donkin-leads.ru",
    post_message_access_key: process.env.POST_MESSAGE_ACCESS_KEY || "SLFKsvlkjLJLSasp3yu8pia14RTLDFJ2432sJ",
    ya_metrika_counter: Number(process.env.YA_METRIKA_COUNTER) || 74355388,
    gtag_id: process.env.GTAG_ID || "UA-198791670-1",
    ...clearObject(args)
});

export type AppConfig = ReturnType<typeof appConfig>;