import { IHttpProvider } from '../../interfaces/http-provider.interface';
import { visitSchema, patrialVisitSchema } from './dto/visit.schema';
import { IVisit } from './enums/visit.interface';
import { AnalyticsEvent } from './enums/analytics-event.enum';
import { IAnalyticsPlugin } from './interfaces/analytics-plugin.interface';
import { HttpProvider } from '../../providers/http.provider';
import { AppConfig } from '../../config/app.config';
import { getQueryParams } from '../../utils/parse-query-string';

export interface IUrlData {
  domain: string;
  href: string;
  path?: string;
  queries?: Record<string, string>;
}

export class OwnAnalytics implements IAnalyticsPlugin {
  constructor(private readonly quizId: string, private readonly appConfig: AppConfig) {
    this.httpProvider = new HttpProvider(this.appConfig);
  }

  private visitId: string;
  private readonly httpProvider: IHttpProvider;
  private queue: Partial<IVisit>[] = [];
  private queueTimer: NodeJS.Timeout | undefined;

  private getUrlData() {
    let data: IUrlData = {
      domain: window.location.host,
      href: window.location.href,
      path: window.location.pathname,
      queries: getQueryParams(location.search.substring(1)),
    }

    return data;
  }

  init() {
    const sessionKey = "dentolo_visit_id_for_" + this.quizId;
    let sessionId = window.sessionStorage.getItem(sessionKey);
    
    if (!sessionId) {
      const visitDto = visitSchema.parse({
        quizId: this.quizId,
        ...this.getUrlData()
      });

      console.log(visitDto);
      
      this.httpProvider.post<IVisit>("/visits", visitDto).then(visit => {
        this.visitId = visit.id;
        window.sessionStorage.setItem(sessionKey, this.visitId);
      });
    } else {
      this.visitId = sessionId;
    }
  }

  track(event: AnalyticsEvent, params?: Record<string, any>) {
    let eventName = "event_" + event;
    let param: Partial<IVisit> = {};

    if (event == AnalyticsEvent.REACH_QUESTION && params?.question) {
      eventName = eventName + "_" + params.question;
    }

    if (event == AnalyticsEvent.REACH_PHONE_FORM && params?.form) {
      param.form_type = params.form
    }

    if (event == AnalyticsEvent.OPEN && params?.trigger) {
      param.open_trigger = params.trigger
    }

    const visitDto = {
      [eventName]: true,
      ...param
    }

    this.updateVisit(visitDto);
  }
  
  private updateVisit(visitDto: Partial<IVisit>) {
    const parsedVisitDto = patrialVisitSchema.parse(visitDto);

    if (!Object.keys(parsedVisitDto).length) return;
    this.queue.push(parsedVisitDto);

    if (!this.queueTimer) {
      this.queueTimer = setInterval(() => {
        const visitDto = this.queue.shift();

        if (!visitDto) {
          if (this.queueTimer) clearInterval(this.queueTimer);
          this.queueTimer = undefined;
          return;
        }

        this.httpProvider.patch(`/visits/${this.visitId}`, visitDto);
      }, 2000);
    }
  }

  setParams: (params: Record<string, any>) => void;
  setABTest: () => void;
}