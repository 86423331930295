import { QuizStatus } from "../enums/quiz-status.enum";
import { IAnswer } from '../interfaces/quiz-data.interface';

export class QuizState {
  readonly status: QuizStatus = QuizStatus.CLOSED;
  readonly wasOpened: boolean = false;
  readonly wasClosed: boolean = false;
  readonly wasLoaded: boolean = false;
  readonly initialized: boolean = false;
  readonly iframeAppended: boolean = false;
  readonly autoOpenTimeoutId?: NodeJS.Timeout = undefined;
  readonly distributorSelectedAnswer?: IAnswer = undefined;
}