import { AppConfig } from "../config/app.config";
import { ApiMethod } from "../enums/api-method.enum";
import { IHttpProvider } from "../interfaces/http-provider.interface";

export class HttpProvider implements IHttpProvider {
  constructor(private readonly config: AppConfig) {}

  public async fetch<T>(url: string, method: ApiMethod, body?: Record<string, any>, attempts = 10): Promise<T> {
    const response = await this.request(this.config.api_host + url, { method, body: JSON.stringify(body) }, attempts);

    if (!response.ok) {
      const message = (await response.json()).message;
      throw message;
    }
    
    return (await response.json()) as T;
  }

  public async request(url: string, requestInit?:RequestInit, attempts: number = 10): Promise<Response> {
    const retry = (e: any) => {
      return new Promise<Response>((resolve) => {
        setTimeout(() => {
          if (attempts === 1) throw new Error(e);
          this.request(url, requestInit, attempts - 1).then(response => resolve(response));
        }, 1000);
      });
    }

    try {
      return await fetch(url, { ...requestInit, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }});
    } catch (e) {
      console.error(e);
      return retry(e);
    }
  }

  public async get<T>(url: string, attempts: number = 10): Promise<T> {
    const response = await this.request(this.config.api_host + url, { method: "GET" }, attempts);
    
    if (!response.ok) {
      const message = (await response.json()).message;
      throw new Error(message);
    }
    
    return await response.json();
  }

  public async post<T>(url: string, data: Record<string, any>, attempts: number = 10): Promise<T> { 
    const response = await this.fetch<T>(url, ApiMethod.POST, data, attempts);
    return response;
   }

  public async patch<T>(url: string, data: Partial<T>, attempts: number = 10): Promise<T> { 
    const response = await this.fetch<T>(url, ApiMethod.PATCH, data, attempts);
    return response;
   }

  public delete(url: string): void {}

}