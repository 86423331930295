export function supplementObject<T extends {}>(obj: T, wth: T): T {
  let field: string;
  for (field in { ...obj, ...wth}) {
    if (obj[field] instanceof Array || !(obj[field] instanceof Object)) {
      obj[field] = obj[field] || wth[field];
      continue;
    }
    obj[field] = {...(wth[field] || {}), ...(obj[field] || {})}
  }

  return obj;
}