export function getQueryParams(search: string) {
  const jsonString = '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}';

  try {
    const parsedObject: Record<string, string> = JSON.parse(jsonString, (key, value) => key === "" ? value : decodeURIComponent(value));
    return parsedObject;
  } catch (e) {
    console.log(e);
    return {}
  }
}
