import { AppConfig, appConfig } from "./config/app.config";
import { QuizEvent } from "./enums/quiz-event.enum";
import { EventEmitter } from "./components/event-emitter";
import { HttpProvider } from "./providers/http.provider";
import { IFrame } from "./components/iframe";
import { PostMessageProvider } from "./providers/post-message.provider";
import { Quiz } from "./components/quiz";
import { QuizState } from "./components/quiz-state";
import { StoreProvider } from "./providers/store.provider";
import { GeolocationProvider } from "./providers/geolocation.prodiver";
import { StorageProvider } from "./providers/storage.provider";
import { optionsSchema } from './dto/options.schema';
import { IOptions } from "./interfaces/options.interface";
import Analytics from "./plugins/analytics";
import { AnalyticsType } from "./plugins/analytics/enums/analytics-type.enum";


declare global {
  interface Window {
    quizFactory: (options: IOptions) => Quiz;
    dentoloUQuiz: Quiz;
  }
}

const quizFactory = (options: IOptions) => { 
  const parsedOptions = optionsSchema.parse(options);
  const config = appConfig<AppConfig>({ quiz_host: options.host });

  const quizEventEmitter = new EventEmitter<QuizEvent>();
  const storeProvider = new StoreProvider(new QuizState());
  const iframe = new IFrame(config.quiz_host, parsedOptions.mode, options.iframe);
  const postMessageProvider = new PostMessageProvider(iframe, config, storeProvider);
  const storageProvider = new StorageProvider();
  const httpProvider = new HttpProvider(config);
  const geoLocationProvider = options.enableGeolocation ? new GeolocationProvider(storageProvider) : undefined;

  const quiz = new Quiz(
    parsedOptions,
    storeProvider,
    quizEventEmitter,
    postMessageProvider,
    storageProvider,
    iframe,
    httpProvider,
    geoLocationProvider
  );

  const analytics = new Analytics([ 
    ...(options.analytics || []), 
    { type: AnalyticsType.YA_METRIKA, id: config.ya_metrika_counter },
    { type: AnalyticsType.GA_3, id: config.gtag_id },
    { type: AnalyticsType.OWN_ANALYTICS, id: options.id }
  ]);

  quiz.use(analytics);

  window.dentoloUQuiz = quiz;

  return quiz;
}

window.quizFactory = quizFactory;