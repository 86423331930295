export function getQueryParams(): Readonly<Record<string, string>> {
  const search = window.location.search.substring(1);
  let queryParams:Record<string, string> = {};
  try {
    const decoded = decodeURI(search);
    const formated = decoded.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"');
    if (formated) queryParams = JSON.parse(`{"${formated}"}`);
    return queryParams;
  } catch (error) {
    console.log(error);
    return {};
  }
}